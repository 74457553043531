import { createGlobalStyle } from 'styled-components';
import { baseStyles } from './baseStyles';
import { customStyles } from './customStyles';
import { globalTypography } from './globalTypography';

export const GlobalStyles = createGlobalStyle`
  ${baseStyles}
  ${globalTypography}
  ${customStyles}
`;

export default GlobalStyles;
