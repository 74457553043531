import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Tabs } from './';
// import { hexToRgb } from '../utils/hexToRgb';
import { theme } from '../styles/theme';
// import { StyledHeart, StyledOutline } from './styled';

const TextContainer = styled.p`
  border: 1px solid ${theme.colors.grey2};
  border-radius: 3px;
  padding: 1em;
  color: ${theme.colors.grey3};
  font-size: ${theme.fontSizeMed};
`;

const Title = styled.span`
  color: ${theme.colors.text_heading};
  font-size: ${theme.fontSizeMed};
  font-weight: bold;
  display: block;
  margin-bottom: 0.5em;
`;

const AdBar = styled.div`
  background-color: ${theme.colors.text_heading};
  color: ${theme.colors.white};
  font-size: ${theme.fontSizeMed};
  font-weight: 300;
  width: 100%;
  padding: 1em;
  cursor: pointer;
  margin-bottom: 1em;
  text-align: center;
`;

const YellowText = styled.span`
  color: ${theme.colors.primary};
  font-weight: bold;
  margin: 0 0.3em;
`;

const StyledTabs = styled(Tabs)`
  width: 100%;
`;

const tabsContent = [
  {
    id: 0,
    title: 'DESCRIÇÃO',
  },
  {
    id: 1,
    title: 'TERMOS DE USO',
  },
];

const ContentComponent = ({ title, text }) => (
  <TextContainer>
    <Title>{title}</Title>
    {text}
  </TextContainer>
);

const AdvantagesDetails = ({ details, termsOfUse }) => {
  const components = [
    <ContentComponent
      key={0}
      title="Descrição da Vantagem"
      text={details}
    />,
    <ContentComponent
      key={1}
      title="Termos de Uso"
      text={termsOfUse}
    />
  ];

  return (
    <>
      <StyledTabs
        content={tabsContent}
        components={components}
        onClick={() => {}}
      />
      <AdBar
        onClick={() => console.log('ok')}
      >
        Economize mais
        <YellowText>
          R$50,00
        </YellowText>
        tornando-se
        <YellowText>
          NOIVA PLUS
        </YellowText>
      </AdBar>
    </>
  );
};

AdvantagesDetails.propTypes = {
  details: PropTypes.string.isRequired,
  termsOfUse: PropTypes.string.isRequired,
};

export default AdvantagesDetails;
