import produce from 'immer';

export default (searchbar, action) => {
  switch (action.type) {
    case 'selectRegion':
      // Função produce recebe o estado original e uma "cópia" a ser alterada
      // No caso searchbar = estado original e draft = cópia a ser alterada
      return produce(searchbar, draft => {
        draft.selectedRegion = action.newRegion;
      })
    
    case 'changeSearch':
      return produce(searchbar, draft => {
        draft.searchString = action.searchString;
      })

      default:
        return searchbar;
  }
}
