export const UPDATE_RESOURCE_1 = 'UPDATE_RESOURCE';
export const UPDATE_RESOURCE_2 = 'UPDATE_RESOURCE_2';
export const UPDATE_ACTIVE_STEP = 'UPDATE_ACTIVE_STEP';
export const UPDATE_FILL_ATTEMPT_STEP_1 = 'UPDATE_FILL_ATTEMPT_STEP_1';
export const UPDATE_FILL_ATTEMPT_STEP_2 = 'UPDATE_FILL_ATTEMPT_STEP_2';
export const UPDATE_FILL_ATTEMPT_STEP_3 = 'UPDATE_FILL_ATTEMPT_STEP_3';
export const UPDATE_COD_EMPRESA = 'UPDATE_COD_EMPRESA';
export const UPDATE_COD_PROCESSO = 'UPDATE_COD_PROCESSO';
export const UPDATE_ENDERECO_DIVULGA = 'UPDATE_ENDERECO_DIVULGA';
export const UPDATE_VALOR_ANALISE = 'UPDATE_VALOR_ANALISE';

