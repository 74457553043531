import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import { Button } from './';
import { hexToRgb } from '../utils/hexToRgb';
import { theme } from '../styles/theme';
import { StyledHeart, StyledOutline } from './styled';
import { AdvantagesDetails } from './';
import { useStateValue } from '../state';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;

  align-self: center;
  padding: 1em;
  margin: 1em;

  background-color: ${theme.colors.white};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 3px 10px 0px ${hexToRgb(theme.colors.grey2, 0.5)};
`;

const HalfButton = styled(Button)`
  background-color: ${props =>
    props.selected ? theme.colors.white : theme.colors.text_heading};
  color: ${props =>
    props.selected ? theme.colors.text_heading : theme.colors.white};
  border-color: ${theme.colors.text_heading};
  min-width: 48%;
  font-size: ${theme.fontSizeSm}px;
`;

const TopContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 1.5em;
`;

const Title = styled.span`
  font-size: ${theme.fontSize}px;
  color: ${theme.colors.text_heading};
  font-weight: bold;
  text-align: left;
  margin-bottom: 0.5em;
`;

const SubTitle = styled.span`
  font-size: ${theme.fontSize}px;
  color: ${theme.colors.grey3};
  font-weight: 300;
  text-align: left;
  margin-bottom: 0.5em;
`;

const Savings = styled.span`
  font-size: ${theme.fontSize}px;
  color: ${theme.colors.primary};
  font-weight: bold;
  text-align: left;
  margin-left: 0.5em;
`;

const Description = styled.p`
  font-size: ${theme.fontSize}px;
  color: ${theme.colors.text_heading};
  font-weight: 300;
  text-align: left;
`;

const SegmentIcon = styled(Img)`
  margin-bottom: 0.5em;
`;

const RadioSection = styled.fieldset`
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: none;
  width: 100%;
  padding: 0;
  margin: 0.5em 0 1em 0;
`;

const RadioGroup = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0.5em;
`;

const RadioInput = styled.input`
  margin: 0;
  margin-right: 0.3em;
`;

const RadioLabel = styled.label`
  color: ${theme.colors.text_heading};
  font-size: ${theme.fontSizeSm}px;
  text-align: center;
  margin: 0;
`;

const ContactLabel = styled.span`
  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.grey3};
  font-weight: 300;
  margin-right: 1em;
`;

const AdvantagesCard = ({
  id, name, supplier, description, savings, isFavorited, details, termsOfUse, icon, cardImg
}) => {
  const [{
    visibility,
  }, dispatch] = useStateValue();

  const [contact, setContact] = useState('phone');

  const renderHeartIcon = () => {
    if (isFavorited) {
      return <StyledHeart />;
    } else {
      return <StyledOutline />;
    }
  };

  return (
    <Wrapper>
      <TopContainer>
        <Img fixed={cardImg} />
        <DataContainer>
          <Title>
            {name}
          </Title>
          <SubTitle>
            {supplier}
          </SubTitle>
          <SegmentIcon fixed={icon} />
          <SubTitle>
            Economize
            <Savings>
              R$ {savings}
            </Savings>
          </SubTitle>
        </DataContainer>
        {renderHeartIcon()}
      </TopContainer>
      <Description>
        {description}
      </Description>
      {(visibility.advantageDetails === id) && (<AdvantagesDetails
        details={details}
        termsOfUse={termsOfUse}
      />)}
      <RadioSection>
        <ContactLabel>Quero ser contactado:</ContactLabel>
        <RadioGroup>
          <RadioInput
            type="radio"
            onChange={() => setContact('phone')}
            value="phone"
            checked={contact === "phone"}
          />
          <RadioLabel>Telefone</RadioLabel>
        </RadioGroup>
        <RadioGroup>
          <RadioInput
            type="radio"
            onChange={() => setContact('whatsapp')}
            value="whatsapp"
            checked={contact === "whatsapp"}
          />
          <RadioLabel>Whatsapp</RadioLabel>
        </RadioGroup>
        <RadioGroup>
          <RadioInput
            type="radio"
            onChange={() => setContact('mail')}
            value="mail"
            checked={contact === "mail"}
          />
          <RadioLabel>E-mail</RadioLabel>
        </RadioGroup>
      </RadioSection>
      <ButtonsContainer>
        <HalfButton
          text="DETALHES"
          click={() => 
            dispatch({
              type: 'toggleAdvantageDetails',
              newVisibility: id,
            })}
          selected={visibility.advantageDetails === id}
        />
        <HalfButton
          text="SOLICITAR ORÇAMENTO"
          click={() =>
            console.log('TESTE')
          }
          // selected={visibility.restrictAccess}
        />
      </ButtonsContainer>
    </Wrapper>
  );
};

AdvantagesCard.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  supplier: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  // segment: PropTypes.string.isRequired,
  savings: PropTypes.number.isRequired,
  isFavorited: PropTypes.bool.isRequired,
  details: PropTypes.string.isRequired,
  termsOfUse: PropTypes.string.isRequired,
};

export default AdvantagesCard;
