import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import theme from '../styles/theme';
import { SearchIcon2, HeartIcon, CertificateIcon } from './icons';

import { medias } from '../utils/mediaQueries';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
`;

const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-around;

  @media ${medias.phoneMax} {
    flex-direction: column;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 27%;

  @media ${medias.phoneMax} {
    width: 100%;
  }
`;

const ItemTitle = styled.h2`
  color: ${theme.colors.secondary};
  font-weight: bold;
  text-align: center;
  margin: ${props => (props.longTitle ? '12px 0 0 0' : '20px 0 20px 0')};
`;

const ItemText = styled.p`
  font-size: ${theme.fontSizeLg * 0.9}px;
  text-align: justify;
  margin: ${props => (props.longTitle ? '12px 0 18px 0' : '18px 0')};
`;

const ItemText2 = styled.p`
  font-size: ${theme.fontSizeLg * 0.9}px;
  text-align: center;
  margin: ${props => (props.longTitle ? '12px 0 18px 0' : '18px 0')};
`;

const LinkA = styled(Link)`
  text-decoration: none;
  border: 2px solid ${theme.colors.primary};
  padding: 10px;
  font-size: 0.7rem;
  width: 100%;
  text-align: center;
  color: ${theme.colors.secondary};
  font-weight: bold;

  :hover {
    text-decoration: none;
    color: ${theme.colors.secondary};
  }
  @media ${medias.phoneMax} {
    margin-bottom: 1rem;
  }
`;

const EntendaBody = () => {
  return (
    <Wrapper>
      <ItemTitle longTitle>Certificação</ItemTitle>
      <ItemText2 longTitle>
        <b>Confiança e informação</b> para o consumidor comprar
        <br />
        <b>Reputação e inteligência</b> para o fornecedor vender
      </ItemText2>

      <ItemText longTitle>
        O CERTIFICADO TRUST foi criado para avaliar critérios financeiros,
        jurídicos e qualitativos dos fornecedores do mercado de eventos.
      </ItemText>
      <hr />
      <Row>
        <ItemContainer>
          <CertificateIcon />
          <ItemTitle longTitle>Análise e Certificação</ItemTitle>
          <ItemText longTitle>
            O fornecedor solicita sua análise, após a aprovação recebe o
            Certificado e passa a ser divulgado no nosso Site (
            <Link to="/fornecedores">
              Clique aqui para fornecedores certificados
            </Link>
            )
          </ItemText>
        </ItemContainer>
        <ItemContainer>
          <SearchIcon2 />
          <ItemTitle longTitle>Clientes pesquisam</ItemTitle>
          <ItemText longTitle>
            Os clientes em momento de compra conseguem pesquisar pelos
            fornecedores do mercado de evento que são certificados.
            <br />
            <br />
          </ItemText>
        </ItemContainer>
        <ItemContainer>
          <HeartIcon />
          <ItemTitle longTitle>Experiência de compra</ItemTitle>
          <ItemText longTitle>
            O consumidor poderá avaliar o serviço prestado através da Ouvidoria,
            criada para ouvir as experiências do cliente em quesitos como
            qualidade técnica e de atendimento.
          </ItemText>
        </ItemContainer>
      </Row>
      <Row>
        <ItemContainer>
          <LinkA to="/entenda/#indicadores">INDICADORES AVALIADOS</LinkA>
        </ItemContainer>
        <ItemContainer>
          <LinkA to="/fornecedores">FORNECEDORES CERTIFICADOS</LinkA>
        </ItemContainer>
        <ItemContainer>
          <LinkA to="/contato">OUVIDORIA</LinkA>
        </ItemContainer>
      </Row>
    </Wrapper>
  );
};

export default EntendaBody;
