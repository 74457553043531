const initialState = {
  loginForm: {
    email: '',
    password: '',
    type: '',
    forgotPasswordEmail: '',
  },
  bridesForm: {
    name: '',
    email: '',
    phone: '',
    date: '',
    state: '',
    city: '',
    location: '',
  },
  supplierForm: {
    cnpj: '',
    company: '',
    trade: '',
    state: '',
    city: '',
    commercialContact: '',
    phone: '',
    email: '',
    date: new Date(),
    activity: '',
    region: '',
    instagram: '',
    site: '',
    know: '',
    summary: '',
    brideNumber: '',
    contractsValue: '',
    marriageNumber: '',
    ceremonials: ['', '', ''],
    suppliers: ['', '', ''],
  },
  franchiseForm: {
    name: '',
    cnpj: '',
    state: '',
    city: '',
    mainContact: '',
    phone: '',
    email: '',
    know: '',
    experience: '',
  },
  contactForm: {
    subject: '',
    name: '',
    email: '',
    message: '',
  },
  requestBudgetForm: {
    name: '',
    email: '',
    phone: '',
    cellphone: '',
    contact: '',
    date: '',
    uf: '',
    city: '',
    location: '',
    guestsNumber: '',
    message: '',
  },
  /*brideWelcomeForm: {
    name: '',
    email: '',
    phone: '',
    cellphone: '',
    uf: '',
    city: '',
    engaged:'',
    marriageCity:'',
    date: new Date(),
    investment:'',
    invited:'',
    party:'',
    local:'',
  },*/
};

export default initialState;
