import { fontFallbacks } from './styleConstants';
import { hexToRgb } from '../utils/hexToRgb';
import theme from './theme';

const fontsizeBreakpoint = '790px';

// These styles are truly global to the app/website as they don't
// mess-up custom layouts and provide a good basis for efficient
// typography
export const globalTypography = `
  :root {
    font-size: ${theme.fontSize}px;
    font-family: "${theme.fontFamilyBody}", ${
  fontFallbacks[theme.familyCategoryBody]
};
    color: ${theme.colors.text};
    font-weight: ${theme.bodyWeight};
  }

  // Give more control to styling hrs
  hr {
    height: 1px;
    border: 0;
    background: ${(hexToRgb(theme.colors.text), 0.7)};
    margin: 2em 0;
  }

  // All headings share the same font-family. Override this if you need
  h1, h2, h3, h4, h5 {
    font-weight: ${theme.headingWeight};
    font-family: ${
      theme.fontFamilyHeading && theme.familyCategoryHeading
        ? `"${theme.fontFamilyHeading}", ${fontFallbacks[
            theme.familyCategoryHeading
          ] || fontFallbacks['sans-serif']}`
        : 'inherit'
    };
    color: ${theme.colors.text_heading};
  }

  @media screen and (min-width: ${fontsizeBreakpoint}) {
    :root {
      font-size: ${theme.fontSizeLg}px;
    }
  }
`;