// STORE ALL CUSTOM HOOKS HERE!
import { useState, useEffect } from 'react';

export const useWindowWidth = () => {
  let windowObj = typeof window !== 'undefined' && window;
  const [width, setWidth] = useState(windowObj.innerWidth);

  useEffect(() => {
    windowObj = typeof window !== 'undefined' && window;
    const handleResize = () => setWidth(windowObj.innerWidth);

    windowObj.addEventListener('resize', handleResize);

    return () => {
      windowObj.removeEventListener('resize', handleResize);
    };
  });

  return width;
}
