// Styled components altamente reutilizáveis e simples
import React from 'react';
import styled from 'styled-components';
import CloseButton from 'react-icons/lib/io/ios-close-outline';

import { theme } from '../../styles/theme';
import { Favorite, FavoriteOutline } from '../icons';
import { Button } from '../';

export const ExitButton = styled(CloseButton)`
  height: 21px;
  width: 21px;
  color: ${theme.colors.error};
  cursor: pointer;
  position: relative;
  /* left: 95%; */
  left: ${props => props.left};
  top: ${props => props.top};
`;

export const TabLine = styled.div`
  background-color: ${theme.colors.grey2};
  height: 2px;
  margin: 1px 0 1px 0;
`;

export const HeroWrapper = styled.div`
  padding-bottom: 285px;
`;

export const StyledHeart = styled(Favorite)`
  cursor: pointer;
`;

export const StyledOutline = styled(FavoriteOutline)`
  cursor: pointer;
`;

export const Title = styled.h4`
  font-size: ${theme.fontSizeLg * 0.9}px;
  font-weight: ${theme.bodyWeight};
  margin: 40px 0 15px 0;
`;

export const Line = styled.div`
  height: 3px;
  width: 60px;
  background-color: ${theme.colors.primary};
  margin: 0 0 40px 0;
`;

export const StyledButton = styled(props => <Button {...props} />)`
  width: 93%;
  background-color: ${theme.colors.text_heading};
  color: ${theme.colors.white};
  margin: 20px 0 0 0;
  border-color: ${theme.colors.text_heading};
`;

export const StyledHalfButton = styled(props => <Button {...props} />)`
  width: 48%;
  background-color: ${theme.colors.text_heading};
  color: ${theme.colors.white};
  margin: 20px 0 0 0;
  border-color: ${theme.colors.text_heading};
`;

export const RedButton = styled(props => <Button {...props} />)`
  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.error};
  border: 2px solid ${theme.colors.error};
  margin-bottom: 0.75em;
`;
