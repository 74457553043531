// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-app-app-jsx": () => import("./../../../src/app/app.jsx" /* webpackChunkName: "component---src-app-app-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-join-us-page-jsx": () => import("./../../../src/pages/joinUsPage.jsx" /* webpackChunkName: "component---src-pages-join-us-page-jsx" */),
  "component---src-pages-supplier-jsx": () => import("./../../../src/pages/supplier.jsx" /* webpackChunkName: "component---src-pages-supplier-jsx" */),
  "component---src-pages-suppliers-list-jsx": () => import("./../../../src/pages/suppliersList.jsx" /* webpackChunkName: "component---src-pages-suppliers-list-jsx" */),
  "component---src-pages-thanks-jsx": () => import("./../../../src/pages/thanks.jsx" /* webpackChunkName: "component---src-pages-thanks-jsx" */),
  "component---src-pages-understand-jsx": () => import("./../../../src/pages/understand.jsx" /* webpackChunkName: "component---src-pages-understand-jsx" */)
}

