import React, { useState } from 'react';
import styled from 'styled-components';

import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import { TitleSubtitle } from './';
import { Link } from 'gatsby';

const StyledContainer = styled.div`
  @media ${medias.laptopMin} {
    margin: 50px 0 50px 0;
  }
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 20px 0 20px 0;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 30%;

  @media ${medias.tabletMin} {
    font-size: 0.8em;
  }
`;

const StyledTitleContainer = styled.div`
  width: 50%;
  min-height: 240px;
`;

const StyledButton = styled.button`
  @media ${medias.phoneMax} {
    font-size: 0.6em;
  }

  background-color: ${props => (props.selected ? theme.colors.secondary : '')};
  border: 1px solid ${theme.colors.secondary};
  border-radius: 3px;
  font-size: 0.7em;
  color: ${props =>
    props.selected ? theme.colors.white : theme.colors.text_heading};
  height: 40px;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em 5em;
  font-weight: normal;
  margin: 5px 0 5px 0;
`;

const LinkA = styled(Link)`
  text-decoration: none;
`;

const JoinUs = () => {
  const [selected, setSelected] = useState(0);
  const [subtitle] = useState({
    0: 'Aumente a confiança e credibilidade da sua empresa para fechar mais negócios.',
    1: 'Informações para quem contrata serviços e produtos para eventos..',
    2: 'Informações para quem contrata serviços e produtos para eventos..',
  });

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <TitleSubtitle
          title="CONFIANÇA & QUALIDADE"
          subtitle={subtitle[selected]}
        />
      </StyledTitleContainer>

      <StyledButtonContainer>
        <LinkA to="/entenda/#indicadores">
          <StyledButton
            selected={selected === 0}
            onClick={() => setSelected(0)}
          >
            INDICADORES
          </StyledButton>
        </LinkA>
        <LinkA to="/entenda">
          <StyledButton
            selected={selected === 1}
            onClick={() => setSelected(1)}
          >
            COMO FUNCIONA
          </StyledButton>
        </LinkA>
        <LinkA to="/junte-se">
          <StyledButton
            selected={selected === 2}
            onClick={() => setSelected(2)}
          >
            SEJA CERTIFICADO
          </StyledButton>
        </LinkA>
      </StyledButtonContainer>
    </StyledContainer>
  );
};

export default JoinUs;
