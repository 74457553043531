import searchbar from './searchbar';
import visibility from './visibility';
import forms from './forms';
import session from './session';

const initialGlobalState = {
  searchbar,
  visibility,
  forms,
  session,
};

export default initialGlobalState