export const baseStyles = `
  *, *::before, *::after {
    box-sizing: inherit;
    font-family: 'Lato';
  }

  html {
    height:100%;
    box-sizing: border-box;
  }

  body {
    height:100%;
    margin: 0;
  }

  // Resetando estilos padrão
  #___gatsby {
    height: 100%;
  }
  div[role="group"][tabindex] {
    height: 100%;
  }
  img {
    max-width: 100%
  }
  
  h2 {
    font-size:1.5em !important
  }
  
  a:hover {
    text-decoration: none !important;
  }

  h2 {
    font-size:1.5em !important
  }

  form {
    width: 100%
  }

  button {
    background: transparent;
    border: none;
    font-size: inherit;
    cursor: pointer;
  }

  ul, ol {
    padding: 0 3em 0 1em;
  }
  
  // lis should have vertical margins and inherit their parents'
  // line-heights for better readability
  li {
    margin: 1.5em 0;
    line-height: inherit;
  }
`;

export default baseStyles;
