import React from 'react';
import TextField from '@material-ui/core/TextField';

//import { theme } from '../styles/theme';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  /*cssLabel: {
    fontSize: theme.fontSizeSm,
    color: theme.colors.grey3,
    '&$cssFocused': {
      color: theme.colors.grey3,
    },
  },
  cssInput: {
    fontSize: theme.fontSizeSm,
    color: theme.colors.text_heading,
  },
  cssFocused: {},
  cssControl: {
    margin: 0,
  },
  cssUnderline: {
    '&:after': {
      borderBottomColor: theme.colors.grey3,
    },
  },*/
};

const MaterialInput = props => {
  const { classes } = props;

  return (
    <TextField
      {...props}
      margin="dense"
      InputProps={{
        classes: {
          root: classes.cssInput,
          focused: classes.cssFocused,
          underline: classes.cssUnderline,
        },
      }}
      InputLabelProps={{
        classes: {
          root: classes.cssLabel,
          focused: classes.cssFocused,
        },
      }}
    />
  );
};

// All material textfield props
MaterialInput.propTypes = {};

export default withStyles(styles)(MaterialInput);
