import React from 'react';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify'; // maybe change this to more generic location

import { Header, Footer } from '../components';
//import { LoginModal } from '../containers';
import { theme } from '../styles/theme';
import { medias } from '../utils/mediaQueries';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../containers/Slider/Slider.css';
import './toast.css';

const Wrapper = styled.div`
  min-height: 100%;
  position: relative;
  @media ${medias.laptopMin} {
    padding-top: 90px;
  }
`;

const Toast = styled(ToastContainer)`
  .toast {
    background-color: ${theme.colors.grey3};
    color: white;
    padding: 1em;
  }
`;

const Layout = ({ children }) => (
  <Wrapper>
    <Toast position="top-center" autoClose={2500} toastClassName="toast" />
    <Header />
    {children}
    <Footer />
  </Wrapper>
);

export default Layout;
