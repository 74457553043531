import React, { useEffect } from 'react';
import { useFormState } from 'react-use-form-state';
import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';
import InputMask from 'react-input-mask';

import {
  MaterialInput,
  MaterialDatePicker,
  MaterialSelect,
  MaterialMenuItem,
  Button,
} from '../components';
import { theme } from '../styles/theme';
import { useStateValue } from '../state';

const RadioGroup = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0.5em;
`;

const SelectGroup = styled(FormControl)`
  display: flex;
  flex-direction: column;
  width: 47.5%;
`;

const RadioLabel = styled.label`
  color: ${theme.colors.text_heading};
  font-size: ${theme.fontSizeSm}px;
  text-align: center;
  margin: 0;
`;

const StyledLabel = styled.label`
  color: ${theme.colors.grey3};
  font-size: ${theme.fontSizeSm}px;
  margin: 0;
  text-align: left;
`;

const RadioInput = styled.input`
  margin: 0;
  margin-right: 0.3em;
`;

const RadioSection = styled.fieldset`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border: none;
  width: 50%;
  padding: 0;
  margin: 0.5em 0;
`;

const InputsSection = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: none;
  width: 100%;
  padding: 0;
  margin: 0;
  margin-bottom: 0.5em;
`;

const StyledDatePicker = styled(props => <MaterialDatePicker {...props}/>)`
  width: 47.5%;
`;

const RowContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  width: 100%;
  margin-bottom: 0.5em;
`;

const StyledButton = styled(Button)`
  width: 47.5%;
  background-color: ${theme.colors.text_heading};
  border-color: ${theme.colors.text_heading};
  color: white;
  font-size: ${theme.fontSizeSm}px;
`;

const ButtonsContainer = styled.section`
  display: flex;
  justify-content: space-around;
  margin-top: 0.5em;
  width: 100%;
`;

const StyledForm = styled.form`
  width: 100%;
`;

const HalfInput = styled(InputMask)`
  width: 47.5%;
`;

const RequestBudgetForm = ({ onCancel }) => {
  const initialFormState = {
    contact: 'phone',
  };

  const [
    {
      forms: { requestBudgetForm },
    },
    dispatch,
  ] = useStateValue();

  useEffect(() => {
    dispatch({
      type: 'changeBudgetForm',
      field: 'contact',
      newText: initialFormState.contact,
    });
  }, []);

  const [formState, { text, radio, select, label }] = useFormState(
    initialFormState,
    {
      withIds: true,
      onChange(e) {
        const { name, value } = e.target;
        console.log(`the ${name} input has changed to ${value}`);
        dispatch({
          type: 'changeBudgetForm',
          field: name,
          newText: value,
        });
      },
    }
  );

  return (
    <StyledForm onSubmit={() => console.log(formState)}>
      <MaterialInput
        {...text('name')}
        style={{ width: '100%' }}
        required
        label="Seu nome"
      />
      <MaterialInput
        {...text('email')}
        required
        style={{ width: '100%' }}
        label="Seu melhor e-mail"
      />
      <InputsSection>
        <HalfInput
          {...text('phone')}
          required
          label="Telefone"
          mask="(99)99999-9999"
        >
          {inputProps => <MaterialInput {...inputProps} />}
        </HalfInput>
        <HalfInput
          {...text('cellphone')}
          required
          label="Celular"
          mask="(99)99999-9999"
        >
          {inputProps => <MaterialInput {...inputProps} />}
        </HalfInput>
      </InputsSection>
      <StyledLabel>Quero ser contactado</StyledLabel>
      <RowContainer>
        <RadioSection>
          <RadioGroup>
            <RadioInput {...radio('contact', 'phone')} />
            <RadioLabel {...label('contact', 'phone')}>Telefone</RadioLabel>
          </RadioGroup>
          <RadioGroup>
            <RadioInput {...radio('contact', 'whatsapp')} />
            <RadioLabel {...label('contact', 'whatsapp')}>Whatsapp</RadioLabel>
          </RadioGroup>
          <RadioGroup>
            <RadioInput {...radio('contact', 'mail')} />
            <RadioLabel {...label('contact', 'mail')}>E-mail</RadioLabel>
          </RadioGroup>
        </RadioSection>
        <StyledDatePicker
          label="Data do casamento"
          format={'DD/MM/YYYY'}
          value={requestBudgetForm.date}
          onChange={date =>
            dispatch({
              type: 'changeBudgetForm',
              field: 'date',
              newText: date,
            })
          }
        />
      </RowContainer>
      <InputsSection>
        <SelectGroup>
          <StyledLabel {...label('uf')}>Estado</StyledLabel>
          <MaterialSelect
            {...select({
              name: 'uf',
              validate: () => {},
            })}
          >
            <MaterialMenuItem value="MG">MG</MaterialMenuItem>
            <MaterialMenuItem value="RJ">RJ</MaterialMenuItem>
            <MaterialMenuItem value="SP">SP</MaterialMenuItem>
          </MaterialSelect>
        </SelectGroup>
        <SelectGroup>
          <StyledLabel {...label('city')}>Cidade</StyledLabel>
          <MaterialSelect
            {...select({
              name: 'city',
              validate: () => {},
            })}
          >
            <MaterialMenuItem value="Belo Horizonte">
              Belo Horizonte
            </MaterialMenuItem>
            <MaterialMenuItem value="Rio de Janeiro">
              Rio de Janeiro
            </MaterialMenuItem>
            <MaterialMenuItem value="São Paulo">São Paulo</MaterialMenuItem>
          </MaterialSelect>
        </SelectGroup>
      </InputsSection>
      <InputsSection>
        <MaterialInput
          {...text('location')}
          required
          style={{ width: '47.5%' }}
          label="Local do evento"
        />
        <MaterialInput
          {...text('guestsNumber')}
          required
          style={{ width: '47.5%' }}
          label="Número de convidados"
        />
      </InputsSection>
      <MaterialInput
        {...text('message')}
        style={{ width: '100%' }}
        label="Mensagem"
      />
      <ButtonsContainer>
        <StyledButton text="Cancelar" click={onCancel} />
        <StyledButton text="Enviar" click={() => {}} />
      </ButtonsContainer>
    </StyledForm>
  );
};

export default RequestBudgetForm;
