import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { theme } from '../../styles/theme';
import {
  HomeIcon,
  EventIcon,
  CheckListIcon,
  ProvidersIcon,
  FavoritesIcon,
  BenefitsIcon,
  RewardIcon,
  IndicateIcon,
  RatingIcon,
} from '../../components/icons';

const Menu = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
`;

const MenuItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 40px;
`;

const Text = styled.p`
  color: ${theme.colors.secondary};
  font-size: ${theme.fontSize * 0.9}px;
  font-weight: bold;
  padding-bottom: 3px;
`;

const Divider = styled.div`
  background-color: ${theme.colors.grey2};
  padding-top: 1px;
  margin: 5px 0px 5px 40px;
`;

const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 10px;
`;
const MobileMenu = ({ isGuest }) => {
  const onClickHome = () => {
    console.log('Home clicked !');
  };

  const onClickEvents = () => {
    console.log('Home clicked !');
  };

  const onClickChecklist = () => {
    console.log('Home clicked !');
  };

  const onClickProviders = () => {
    console.log('Home clicked !');
  };

  const onClickFavorites = () => {
    console.log('Home clicked !');
  };

  const onClickBenefits = () => {
    console.log('Home clicked !');
  };

  const onClickRewards = () => {
    console.log('Home clicked !');
  };

  const onClickIndicate = () => {
    console.log('Home clicked !');
  };

  const onClickRating = () => {
    console.log('Home clicked !');
  };

  return (
    <Menu>
      <MenuItemContainer onClick={onClickHome}>
        <IconsWrapper>
          <HomeIcon />
        </IconsWrapper>
        <Text>Home</Text>
      </MenuItemContainer>
      <Divider />
      {!isGuest && (<MenuItemContainer onClick={onClickEvents}>
        <IconsWrapper>
          <EventIcon />
        </IconsWrapper>
        <Text>Eventos</Text>
      </MenuItemContainer>)}
      {!isGuest && <Divider />}
      <MenuItemContainer onClick={onClickChecklist}>
        <IconsWrapper>
          <CheckListIcon />
        </IconsWrapper>
        <Text>Checklist</Text>
      </MenuItemContainer>
      <Divider />
      <MenuItemContainer onClick={onClickProviders}>
        <IconsWrapper>
          <ProvidersIcon />
        </IconsWrapper>
        <Text>Fornecedores</Text>
      </MenuItemContainer>
      <Divider />
      <MenuItemContainer onClick={onClickFavorites}>
        <IconsWrapper>
          <FavoritesIcon />
        </IconsWrapper>
        <Text>Favoritos</Text>
      </MenuItemContainer>
      <Divider />
      <MenuItemContainer onClick={onClickBenefits}>
        <IconsWrapper>
          <BenefitsIcon />
        </IconsWrapper>
        <Text>Vantagens</Text>
      </MenuItemContainer>
      <Divider />
      <MenuItemContainer onClick={onClickRewards}>
        <IconsWrapper>
          <RewardIcon />
        </IconsWrapper>
        <Text>Prêmios</Text>
      </MenuItemContainer>
      {!isGuest && <Divider />}
      {!isGuest && (<MenuItemContainer onClick={onClickIndicate}>
        <IconsWrapper>
          <IndicateIcon />
        </IconsWrapper>
        <Text> Indicar</Text>
      </MenuItemContainer>)}
      {!isGuest && <Divider />}
      {!isGuest && (<MenuItemContainer onClick={onClickRating}>
        <IconsWrapper>
          <RatingIcon />
        </IconsWrapper>
        <Text> Avaliações</Text>
      </MenuItemContainer>)}
    </Menu>
  );
};

MobileMenu.propTypes = {
  isGuest: PropTypes.bool.isRequired,
};

export default MobileMenu;
