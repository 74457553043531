const initialState = {
  loginModal: false,
  passwordModal: false,
  fianceeAccess: false,
  supplierAccess: false,
  franchiseAccess: false,
  restrictAccess: false,
  suppliersInfo: false,
  budgetForm: false,
  detailsAndAdvantages: false,
  advantageDetails: -1,
  mobileMenu: false,
}

export default initialState;
