import React from 'react';

import { LayoutBasis } from './src/components';
import { MobileMenuModal } from './src/containers/BridesMenu';
import { HeroWrapper } from './src/components/styled';
import { StateProvider } from './src/state';
import initialGlobalState from './src/state/stores';
import mainReducer from './src/state/reducers';
import Layout from './src/styles/Layout';
import { Provider } from 'react-redux';
import { Store } from './src/redux/store';

import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

const bugsnagClient = bugsnag('bcbb0831732ca6465e4308390d336eaf');
bugsnagClient.use(bugsnagReact, React);

// wrap your entire app tree in the ErrorBoundary provided
const ErrorBoundary = bugsnagClient.getPlugin('react');

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return (
   
      <LayoutBasis {...props}>
        <Layout>
          <MobileMenuModal />
          <HeroWrapper> {element}</HeroWrapper>{' '}
        </Layout>{' '}
      </LayoutBasis>
   
  );
};

export const wrapRootElement = ({ element }) => {
  return (
    <ErrorBoundary>
       <Provider store={Store}>
        <StateProvider initialState={initialGlobalState} reducer={mainReducer}>
          {' '}
          {element}{' '}
        </StateProvider>{' '}
      </Provider>
    </ErrorBoundary>
  );
};
