import { UPDATE_RESOURCE_1, UPDATE_RESOURCE_2, UPDATE_ACTIVE_STEP,UPDATE_FILL_ATTEMPT_STEP_1, UPDATE_COD_EMPRESA, UPDATE_COD_PROCESSO, UPDATE_FILL_ATTEMPT_STEP_2, UPDATE_FILL_ATTEMPT_STEP_3, UPDATE_ENDERECO_DIVULGA ,UPDATE_VALOR_ANALISE} from '../actions/actionTypes';

const initialState = {
  codEmpresa: "",
  codProcesso:"",
  step1_newValue: {
    cnpj: "",
    nomeEmpresa: "",
    email: "",
    telefone: "",
    cidade: "",
    uf: "",
    logradouro: "",
    numero: "",
    cep: "",
    complemento: "",
    segmento: {
      label: "",
      value: ""
    },
    segmento_outros: "",
    processo: {
      label: "TRUST16",
      value: "1"
    },
    promo_code: "",
  }, 
  step2_newValue: {
    aceite: true,
    respCert: "",
    emailRespCert: "",
    telRespCert: "",
    nomeFantasia: "",
    site:"",
    emailDivulgacao:"",
    telefoneDivulgacao:"",
    enderecosIguais:1,
    socios_rowOrder: "1"
  },
  enderecoDivulga: {
    cep1: "",
    logradouro1:"",
    numero1: "",
    complemento1: "",
    cidade1:"",
    uf1:"",
  },
  activeStep: 0,
  alreadySavedFillAttemptStep1: false,
  alreadySavedFillAttemptStep2: false,
  alreadySavedFillAttemptStep3: false,
  ValorAnalise:false
};

export const wizardReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_RESOURCE_1:
      return { ...state, step1_newValue: action.newValue }

    case UPDATE_RESOURCE_2:
      return { ...state, step2_newValue: action.newValue }
    
    case UPDATE_ENDERECO_DIVULGA:
      return { ...state, enderecoDivulga: action.newValue }

    case UPDATE_ACTIVE_STEP:
        return { ...state, activeStep: action.newValue }
    
    case UPDATE_FILL_ATTEMPT_STEP_1:
      return { ...state, alreadySavedFillAttemptStep1: action.newValue }
    
    case UPDATE_FILL_ATTEMPT_STEP_2:
      return { ...state, alreadySavedFillAttemptStep2: action.newValue }

    case UPDATE_FILL_ATTEMPT_STEP_3:
      return { ...state, alreadySavedFillAttemptStep3: action.newValue }

    case UPDATE_COD_EMPRESA:
      return { ...state, codEmpresa: action.newValue }

    case UPDATE_COD_PROCESSO:
      return { ...state, codProcesso: action.newValue }
    
    case UPDATE_VALOR_ANALISE:
      return { ...state, ValorAnalise: action.newValue }

    default:
      return state;
  }
};

// export const clickReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case 'CLICK_UPDATE_VALUE':
//       return {
//         ...state,
//         newValue: action.newValue
//       };
//     default:
//       return state;
//   }
// };