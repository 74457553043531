export const theme = {
  fontSizeSm: 14,
  fontSize: 16,
  fontSizeMed: 18,
  fontSizeLg: 20,
  fontSizeXlg: 24,
  fontSizeTitle: 70,
  bodyWeight: 300,
  headingWeight: 700,
  fontFamilyBody: 'Lato',
  familyCategoryBody: 'sans-serif',
  colors: {
    // Cor do texto de cabeçalhos
    text_heading: '#040707',
    // Cor do texto pro resto
    text: '#808285',
    // Vocês podem vir a precisar de variações do amarelo da marca
    // pra evitar contrastes muito baixos
    primary_light: 'gold',
    primary: '#53adb3', // Azul TRUST
    primary_dark: 'gold',
    // Preto usado nos backgrounds e botões
    secondary: '#040707',
    // Vermelho usado em momentos específicos
    error: '#ef3024',
    // Recomendo adicionar todas as variações de cinza pra não
    // criar 1001 variações
    grey1: '#f1f2f2',
    grey2: '#cdcccc',
    grey3: '#808285',
    // Tem duas cores (#101010 e #e7f1f4) que não achei no design, mas que o Zeplin apontou como existentes
    white: '#ffffff',
  },
  // Sugestão: padronizar largura máxima de containers
  container: {
    lg: '1320px',
    md: '1090px',
    sm: '900px',
  },
  // Sugestão: transições padronizadas p/ animações
  transitions: {
    fast: '.15s ease',
    default: '.3s cubic-bezier(0.71, 0.06, 0.25, 1)',
    slow: '.6s ease',
  },
  // Sugestão: padronizar as sombras (box-shadow)
  // NOTA: ainda não usei valores certinhos do Zeplin
  shadows: {
    one: '0px 4px 4px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.2)',
    two: '0px 8px 8px rgba(0, 0, 0, 0.2), 0px 4px 4px rgba(0, 0, 0, 0.2)',
    three: '0px 16px 16px rgba(0, 0, 0, 0.2), 0px 8px 8px rgba(0, 0, 0, 0.2)',
    four: '0px 24px 24px rgba(0, 0, 0, 0.2), 0px 12px 12px rgba(0, 0, 0, 0.2)',
    five: '0px 32px 32px rgba(34, 34, 43, 0.2), 0px 16px 16px rgba(34, 34, 43, 0.2)',
  },
};

export default theme;