import React from 'react';
import styled from 'styled-components';

import theme from '../styles/theme';
import { hexToRgb } from '../utils/hexToRgb';
import { SegmentList } from '../components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 90%;
  padding: 2em 0;

  border: 1px solid ${theme.colors.grey1};
  border-radius: 20px;
  background-color: ${theme.colors.white};
  box-shadow: 0px 4px 8px 0 ${hexToRgb(theme.colors.grey2, 0.5)};
`;

const Title = styled.p`
  font-size: ${theme.fontSize}px;
  text-align: left;
  color: ${theme.colors.text_heading};
  font-weight: bold;
  margin-left: 3em;
`;

const mockSegments = [
  {
    id: 0,
    title: 'ALIANÇAS',
    icon: '',
  },
  {
    id: 1,
    title: 'BUFFET',
    icon: '',
  },
];

const mockSegs2 = [
  {
    id: 0,
    title: 'ESPAÇO',
    icon: '',
  },
];

const mockSegs3 = [
  {
    id: 0,
    title: 'MÚSICA PARA CERIMÔNIA',
    icon: '',
  },
  {
    id: 1,
    title: 'BANDA',
    icon: '',
  },
  {
    id: 2,
    title: 'DJ & BOATE',
    icon: '',
  },
  {
    id: 3,
    title: 'DECORAÇÃO',
    icon: '',
  },
];

// @TODO Add start icon
export const FeaturedSegments = () => {
  return (
    <Wrapper>
      <Title>Segmentos que pretendo contratar no momento</Title>
      <SegmentList title="Fase Inicial" items={mockSegments} width="100%" />
      <SegmentList title="Fase Intermediária" items={mockSegs2} width="100%" />
      <SegmentList title="Fase Final" items={mockSegs3} width="100%" />
    </Wrapper>
  );
};

export default FeaturedSegments;
