import produce from 'immer';

export default (form, action) => {
  switch (action.type) {
    case 'changeBrideForm':
      return produce(form, draft => {
        draft.bridesForm[action.field] = action.newText;
      });

    case 'changeLoginForm':
      return produce(form, draft => {
        draft.loginForm[action.field] = action.newText;
      });

    case 'changeSupplierForm':
      return produce(form, draft => {
        draft.supplierForm[action.field] = action.newText;
      });

    case 'changeFranchiseForm':
      return produce(form, draft => {
        draft.franchiseForm[action.field] = action.newText;
      });

    case 'changeContactForm':
      return produce(form, draft => {
        draft.contactForm[action.field] = action.newText;
      });

    case 'changeBudgetForm':
      // return {
      //   ...form,
      //   budgetForm: {
      //     ...form.budgetForm,
      //     [action.field]: action.newText,
      //   }
      // }
      return produce(form, draft => {
        draft.requestBudgetForm[action.field] = action.newText;
      });

      //case 'changeBrideWelcomeForm':
      //return produce(form, draft => {
      //  draft.brideWelcomeForm[action.field] = action.newText;
      //});

    default:
      return form;
  }
};
