import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { theme } from '../../styles/theme';
import {
  HomeIcon,
  EventIcon,
  CheckListIcon,
  ProvidersIcon,
  FavoritesIcon,
  BenefitsIcon,
  RewardIcon,
  IndicateIcon,
  RatingIcon,
} from '../../components/icons';

const Menu = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
`;

const MenuItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 5px;
`;

const Text = styled.p`
  color: ${props =>
    props.color ? theme.colors.primary : theme.colors.secondary};
  font-size: ${theme.fontSize * 0.9}px;
  font-weight: bold;
  padding-bottom: 3px;
`;

const WebMenu = ({ isGuest }) => {
  const onClickHome = () => {
    console.log('Home clicked !');
  };

  const onClickEvents = () => {
    console.log('Home clicked !');
  };

  const onClickChecklist = () => {
    console.log('Home clicked !');
  };

  const onClickProviders = () => {
    console.log('Home clicked !');
  };

  const onClickFavorites = () => {
    console.log('Home clicked !');
  };

  const onClickBenefits = () => {
    console.log('Home clicked !');
  };

  const onClickRewards = () => {
    console.log('Home clicked !');
  };

  const onClickIndicate = () => {
    console.log('Home clicked !');
  };

  const onClickRating = () => {
    console.log('Home clicked !');
  };

  return (
    <Menu>
      <MenuItemContainer onClick={onClickHome}>
        <HomeIcon />
        <Text>Home</Text>
      </MenuItemContainer>
      {!isGuest && (<MenuItemContainer onClick={onClickEvents}>
        <EventIcon />
        <Text>Eventos</Text>
      </MenuItemContainer>)}
      <MenuItemContainer onClick={onClickChecklist}>
        <CheckListIcon />
        <Text>Checklist</Text>
      </MenuItemContainer>
      <MenuItemContainer onClick={onClickProviders}>
        <ProvidersIcon />
        <Text>Fornecedores</Text>
      </MenuItemContainer>
      <MenuItemContainer onClick={onClickFavorites}>
        <FavoritesIcon />
        <Text>Favoritos</Text>
      </MenuItemContainer>
      <MenuItemContainer onClick={onClickBenefits}>
        <BenefitsIcon />
        <Text>Vantagens</Text>
      </MenuItemContainer>
      <MenuItemContainer onClick={onClickRewards}>
        <RewardIcon />
        <Text>Prêmios</Text>
      </MenuItemContainer>
      {!isGuest && (<MenuItemContainer onClick={onClickIndicate}>
        <IndicateIcon />
        <Text> Indicar</Text>
      </MenuItemContainer>)}
      {!isGuest && (<MenuItemContainer onClick={onClickRating}>
        <RatingIcon />
        <Text> Avaliações</Text>
      </MenuItemContainer>)}
    </Menu>
  );
};

WebMenu.propTypes = {
  isGuest: PropTypes.bool.isRequired,
};

export default WebMenu;
