import visibilityReducer from './visibilityReducer';
import searchbarReducer from './searchbarReducer';
import formsReducer from './formsReducer';
import sessionReducer from './sessionReducer';

export default ({ visibility, searchbar, forms, session }, action) => ({
  visibility: visibilityReducer(visibility, action),
  searchbar: searchbarReducer(searchbar, action),
  forms: formsReducer(forms, action),
  session: sessionReducer(session, action),
});
