import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Dropdown } from './';
import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import { hexToRgb } from '../utils/hexToRgb';
import { SearchIcon } from './icons';
import Axios from 'axios';

const StyledContainer = styled.div`
  @media ${medias.laptopMin} {
    background-color: ${hexToRgb(theme.colors.secondary, 0.8)};
    width: 60%;
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  height: 78px;
  background-color: ${theme.colors.secondary};
  justify-content: space-around;
`;

const StyledLineContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
`;

const StyledRegionContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-content: center;
  justify-content: flex-end;
  padding-top: 2px;
`;

const StyledLine = styled.div`
  height: 2px;
  background-color: ${theme.colors.white};
`;

const StyledVerticalLine = styled.div`
  width: 1px;
  height: 30px;
  background-color: ${theme.colors.white};
  margin: 5px 0 3px 0;
`;

const StyledInput = styled.input`
  background-color: transparent;
  border: none;
  font-family: 'Lato';
  font-size: ${theme.fontSize}px;
  font-weight: bold;
  line-height: 2.38;
  letter-spacing: -0.4px;
  text-align: left;
  color: ${theme.colors.white};

  padding-left: 5px;
`;

const StyledIcon = styled(SearchIcon)`
  margin: 10px 15px 0 10px;

  width: 20px;
  height: 20px;

  &:hover {
    cursor: pointer;
  }
`;

const StyledDiv = styled.div`
  position: absolute;
  left: 50%;
  top: 68%;
  transform: translate(-50%, -50%);
`;

const StyledP = styled.p`
  display: none;

  @media (min-width: 940px) {
    display: ${props => (props.isHome ? 'initial' : 'none')};
    font-size: 20px;
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    text-shadow: 0 2px 2px rgba(4, 7, 7, 0.3);
  }
`;

const Wrapper = styled.div`
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
`;

export default class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cidadesList: [],
      searchbar: {
        searchString: '',
        selectedRegion: '',
      },
    };

    // @TODO Receive this function as a prop
    this.onSearch = () => {
      const { searchbar } = this.state;
      if (searchbar.searchString === '' && searchbar.selectedRegion === '') {
        window.location = '/fornecedores';
      } else {
        window.location =
          '/fornecedores?s=' +
          searchbar.searchString +
          '&r=' +
          searchbar.selectedRegion;
      }
    };

    // Action to change global state when input changes
    this.onChangeInput = event => {
      const { searchbar } = this.state;
      this.setState({
        searchbar: {
          ...searchbar,
          searchString: event.target.value,
        },
      });
    };

    // Action to change selected region when dropdown value changes
    this.onSelectRegion = selectedRegion => {
      const { searchbar } = this.state;
      this.setState({
        searchbar: {
          ...searchbar,
          selectedRegion: selectedRegion,
        },
      });
    };

    this.loadCidades = () => {
      Axios.get(
        // eslint-disable-next-line no-undef
        `${process.env.GATSBY_API_URL_PROD}Empresa/cidadesCertificadas`
      ).then(response => {
        const cityArray = [];
        response.data.forEach((item, index) => {
          cityArray.push({
            id: index + 1,
            name: item.cidade,
            selected: false,
          });
        });
        this.setState({
          cidadesList: cityArray,
        });
      });
    };
  }

  componentDidMount() {
    this.loadCidades();
  }

  render() {
    const { cidadesList, searchbar } = this.state;
    const { className, isHome } = this.props;
    return (
      <Wrapper>
        <StyledDiv>
          <StyledP isHome={isHome}>
            Encontre fornecedores certificados na sua região
          </StyledP>
        </StyledDiv>
        <StyledContainer className={className}>
          <StyledLineContainer>
            <StyledInput
              type="text"
              placeholder="Fornecedor ou Segmento"
              onChange={event => this.onChangeInput(event)}
            />
            <StyledLine />
          </StyledLineContainer>

          <StyledLineContainer>
            <StyledRegionContainer>
              <Dropdown
                value={searchbar.selectedRegion}
                list={cidadesList}
                onSelectItem={this.onSelectRegion}
                placeholder="Cidade"
              />
              <StyledVerticalLine />
              <StyledIcon onClick={() => this.onSearch()} />
            </StyledRegionContainer>
            <StyledLine />
          </StyledLineContainer>
        </StyledContainer>
      </Wrapper>
    );
  }
}

SearchBar.propTypes = {
  className: PropTypes.string,
  isHome: PropTypes.bool.isRequired,
};
