import React, { useState } from 'react';
import styled from 'styled-components';

import { Tabs } from './';
import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import { hexToRgb } from '../utils/hexToRgb';
import { CirclePlusIcon, CircleMinusIcon } from './icons';
import { Link } from 'gatsby';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 50px 0;

  background-color: ${theme.colors.white};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 3px 10px 0px ${hexToRgb(theme.colors.grey2, 0.5)};
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 0 0 20px;

  cursor: pointer;
`;

const Line = styled.div`
  background-color: ${theme.colors.grey1};
  height: 1px;
  width: 100%;
  margin: 20px 0 20px 0;
`;

const ItemTitle = styled.h4`
  @media ${medias.phoneMax} {
    font-size: 0.8em;
  }

  font-size: ${theme.fontSize * 1.06}px;
  font-weight: bold;
  color: ${theme.colors.text_heading};
  margin: 0;
`;

const ItemText = styled.div`
  @media ${medias.phoneMax} {
    font-size: 0.9em;
  }

  display: flex;
  align-self: center;
  width: 83%;
  font-size: ${theme.fontSizeLg * 0.9}px;
  color: ${theme.colors.grey3};
  margin: 20px 0 20px 0;
  /*overflow: hidden;*/
`;

const tabContent = [
  {
    id: 0,
    title: 'EMPRESAS',
  },
  {
    id: 1,
    title: 'CONSUMIDORES',
  },
  /*{
    id: 2,
    title: 'FRANQUIAS',
  },*/
];

const StyledDiv = styled.div`
  max-width: 6.333333% !important;
  @media ${medias.phoneMax} {
    max-width: initial !important;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: underline !important;
  cursor: pointer;
  font-weight: bold;
  color: ${theme.colors.text};

  :hover {
    color: ${theme.colors.text};
    text-decoration: underline !important;
  }
`;

//@TODO Slide-up and slide-down transitions
const FAQCard = () => {
  const [openedQuestion, setOpenedQuestion] = useState(0);
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = id => {
    setCurrentTab(id);
    setOpenedQuestion(0);
  };

  return (
    <Wrapper>
      <Tabs content={tabContent} onClick={id => handleTabChange(id)} />
      <Card>
        {itemsContent[currentTab].map(({ id, title, text }) => (
          <Item key={id} className="row">
            <TitleContainer onClick={() => setOpenedQuestion(id)}>
              <StyledDiv className="col-md-1 w-auto pr-0 pl-0">
                {id === openedQuestion ? (
                  <CircleMinusIcon />
                ) : (
                  <CirclePlusIcon />
                )}
              </StyledDiv>
              <div className="col-md-11 w-auto pr-0 pl-0">
                <ItemTitle>{title}</ItemTitle>
              </div>
            </TitleContainer>
            {id === openedQuestion && (
              <ItemText aria-hidden={id === openedQuestion}>{text}</ItemText>
            )}
            <Line />
          </Item>
        ))}
      </Card>
    </Wrapper>
  );
};

export default FAQCard;

const Pergunta0 = () => (
  <div>
    Além dos <StyledLink to="/entenda/#indicadores">indicadores</StyledLink>, a
    empresa que deseja se certificar precisa ter pelo menos 2 anos de abertura
    do CNPJ com exceção dos Buffets que são 5 anos.
  </div>
);

const Pergunta1 = () => (
  <div>
    Deve, para atualizarmos os dados qualitativos dos fornecedores. Acesse a{' '}
    <StyledLink to="/contato">Ouvidoria</StyledLink> para fazer suas
    considerações. Os feedbacks serão utilizados internamente e não serão
    publicados.
  </div>
);

const Pergunta2 = () => (
  <div>
    Para saber, você pode <StyledLink to="/">pesquisar</StyledLink> a cidade e o
    nome do fornecedor. Assim você tem informações sobre a validade do selo,
    quando o fornecedor foi avaliado pela última vez e se está em processo de
    reavaliação.
  </div>
);

// mockup data
const itemsContent = {
  0: [
    {
      id: 0,
      title: 'Quais as exigências além dos indicadores?',
      text: <Pergunta0 />,
    },
    {
      id: 1,
      title: 'O Certificado é pago?',
      text:
        'Sim, e acontece em dois momentos: a taxa de análise e após a aprovação a cessão de uso do certificado, que poderá ser dividida em até 12x. Caso não seja aprovado na análise, o fornecedor não terá o ressarcimento dessa taxa uma vez que a mesma foi realizada e poderá refazer a análise após 30 dias.',
    },
    {
      id: 2,
      title: 'Qual a validade do certificado?',
      text:
        'O Certificado é válido por um ano com exceção dos segmentos Decoração e Buffet, que é de 6 meses. Caso sejam reprovadas na reavaliação, o certificado ficará suspenso até a regularização.',
    },
    {
      id: 3,
      title: 'Se for reprovado, posso tentar novamente a certificação?',
      text:
        'Você pode tentar novamente no período de 30 dias, com novo custo da taxa de análise. ',
    },
    {
      id: 4,
      title: 'Quais segmentos podem ser certificados?',
      text:
        'Qualquer segmento, sendo que Salão de Festa e Buffet possuem exigências específicas (Salão de festa - AVCB e Buffet - Alvará Sanitário)',
    },
  ],
  1: [
    {
      id: 0,
      title:
        'Se um cliente tiver algum problema com algum fornecedor certificado, pode ser relatado para o Trust?',
      text: <Pergunta1 />,
    },
    {
      id: 1,
      title: 'Como sei que o certificado é verdadeiro?',
      text: <Pergunta2 />,
    },
  ],
  2: [
    {
      id: 0,
      title: 'Donec lacinia vitae leo eget consequat?',
      text:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar nisl non risus commodo bibendum. Quisque lorem leo, aliquet consectetur euismod nec, convallis et velit. Donec ultrices lacus vel convallis convallis. Donec hendrerit eu massa sit amet pretium. Quisque eu elementum dolor. Curabitur quis ipsum vel urna sollicitudin ultricies. In suscipit, nibh sed aliquet pellentesque, ex eros dignissim arcu, a interdum lectus mauris ultrices nisl. Suspendisse mauris velit, tincidunt ut elit nec, rhoncus tempus magna. Sed vitae massa non sapien porttitor ultricies. Donec condimentum consectetur tortor, et volutpat orci volutpat et. Duis elementum purus id molestie varius. Phasellus ac tincidunt nunc. Donec auctor magna eget est vehicula volutpat. Curabitur nunc sem, volutpat nec finibus nec, eleifend sit amet ante.',
    },
  ],
};
