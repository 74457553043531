import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { medias } from '../utils/mediaQueries';
import { theme } from '../styles/theme';
import { Button } from './';
import { hexToRgb } from '../utils/hexToRgb';
import logo from '../images/trust.png';

import ReactGA from 'react-ga';

ReactGA.initialize('UA-121057396-1');
if (typeof window !== 'undefined') {
  ReactGA.pageview(window.location.pathname + window.location.search);
}

function loadTawk() {
  var Tawk_API = Tawk_API || {};
  (function() {
    if (typeof window !== 'undefined') {
      var s1 = document.createElement('script'),
        s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/5d2d2168bfcb827ab0cbee38/default';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    }
  })();
}

const BaseWrapper = styled.header`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 80px;
  border: 1px solid ${theme.colors.grey1};
  box-shadow: 0px 4px 8px 0 rgba(205, 204, 204, 0.3);
  text-align: center;
  z-index: 2;
  background-color: ${() => hexToRgb(theme.colors.white, 0.85)};

  @media ${medias.laptopMin} {
    position: fixed;
    height: 90px;
    top: 0;
    left: 0;
  }
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  font-size: ${theme.fontSizeSm}px;
  padding: 0px 7px;
  justify-content: space-between;
  align-items: center;
`;

const BaseChainContainer = styled.div`
  align-self: center;
  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.text_heading};
  font-weight: 300;
  text-decoration: none;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;

  @media ${medias.laptopMin} {
    padding: 0px 50px;
  }
`;

const LogoContainer = styled.div`
  align-self: center;
`;

const StyledButton = styled(props => <Button {...props} />)`
  margin: 0 20px;
  display: flex;
  width: 100px;
  text-decoration: none;

  @media ${medias.tabletMin} {
    width: auto;
  }

  @media ${medias.phoneMax} {
    font-size: 9px;
  }
`;

const LinkA = styled(Link)`
  text-decoration: none;
`;

const StyledLink = styled(Link)`
  color: ${theme.colors.text_heading};
  display: flex;
  align-items: center;
  text-decoration: 'none';
  color: #040707 !important;
  margin-right: 15px;
  font-size: 0.8rem;
  line-height: 20px;

  :hover {
    border-bottom: 2px solid #53adb3;
  }
`;

const StyledA = styled.a`
  color: ${theme.colors.text_heading};
  display: flex;
  align-items: center;
  text-decoration: 'none';
  color: #040707 !important;
  margin-right: 15px;
  font-size: 0.8rem;
  line-height: 20px;

  :hover {
    border-bottom: 2px solid #53adb3;
  }
`;

const Logo = styled.img`
  width: 125px;
  margin: 0px 15px;

  @media (max-width: 377px) {
    width: 100px;
    margin: 0;
  }

  @media (max-width: 335px) {
    width: 78px;
    margin: 0;
  }
`;

const Header = () => {
  const Wrapper = BaseWrapper;
  const ChainContainer = BaseChainContainer;

  //@TODO Configure route to main page
  const onClickLogo = () => {
    console.log('Logo clicked!');
  };

  const publicHeader = () => {
    loadTawk();

    return (
      <Content>
        <LinkA to="/">
          <LogoContainer>
            <Logo src={logo} onClick={onClickLogo} />
          </LogoContainer>
        </LinkA>
        <ChainContainer>
          <StyledA href="https://blog.certificadotrust.com/">Blog</StyledA>
          <StyledLink to="/entenda">Entenda</StyledLink>
          <LinkA to="/junte-se">
            <StyledButton text="SEJA CERTIFICADO" />
          </LinkA>
        </ChainContainer>
      </Content>
    );
  };

  const header = publicHeader();
  return <Wrapper>{header}</Wrapper>;
};

Header.propTypes = {
  data: PropTypes.string,
};

export default Header;
