import React from 'react';
import styled from 'styled-components';
import { useFormState } from 'react-use-form-state';
import FormControl from '@material-ui/core/FormControl';

import theme from '../styles/theme';
import { useStateValue } from '../state';
import {
  MaterialSelect,
  MaterialMenuItem,
} from './';

const Wrapper = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 2em;
`;

const SelectGroup = styled(FormControl)`
  display: flex;
  flex-direction: column;
  width: 45%;
`;

const StyledText = styled.p`
  color: ${props =>
    props.color ? theme.colors.primary : theme.colors.secondary};
  font-size: ${props =>
    props.title ? theme.fontSize * 0.9 : theme.fontSize}px;
  font-weight: bold;
  margin-bottom: 12px;
  margin-left: 3px;
`;

export const DesktopMenuSelects = () => {
  const [{ session }, dispatch] = useStateValue();
  const { selectedEvent, selectedRegion } = session;
  const initialFormState = {
    selectedEvent,
    selectedRegion,
  };

  const [, { select }] = useFormState(
    initialFormState,
    {
      withIds: true,
      onChange(e) {
        const { name, value } = e.target;
        console.log(`the ${name} input has changed to ${value}`);
        dispatch({
          type: 'changeSessionForm',
          field: name,
          newText: value,
        });
      },
    }
  );

  const mockEvents = [
    {
      key: 0,
      value: '10/10/2019 - Casamento Fulano',
    },
    {
      key: 1,
      value: '12/10/2019 - Casamento Ciclano',
    },
    {
      key: 2,
      value: '17/11/2019 - Casamento Beltrano',
    },
  ];

  const mockRegions = [
    {
      key: 0,
      value: 'MG - Belo Horizonte',
    },
    {
      key: 1,
      value: 'SP - São Paulo',
    },
  ];

  const eventOptions = mockEvents.map(({ key, value, }) => (
    <MaterialMenuItem
      value={value}
      key={key}
      id="SELECT-OPTION"
    >
      {value}
    </MaterialMenuItem>
  ));

  const regionOptions = mockRegions.map(({ key, value, }) => (
    <MaterialMenuItem
      value={value}
      key={key}
      id="SELECT-OPTION"
    >
      {value}
    </MaterialMenuItem>
  ));

  return (
    <Wrapper>
      <SelectGroup>
        <StyledText color>Operando evento:</StyledText>
        <MaterialSelect
          {...select({
            name: 'selectedEvent',
            validate: () => {},
          })}
        >
          {eventOptions}
        </MaterialSelect>
      </SelectGroup>
      <SelectGroup>
        <StyledText color>Fornecedores de:</StyledText>
        <MaterialSelect
          {...select({
            name: 'selectedRegion',
            validate: () => {},
          })}
        >
          {regionOptions}
        </MaterialSelect>
      </SelectGroup>
    </Wrapper>
  );
};

export default DesktopMenuSelects;
