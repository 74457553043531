import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';
import { useFormState } from 'react-use-form-state';

import { theme } from '../../styles/theme';
import { useStateValue } from '../../state';
import {
  LoginChainIcon,
  MenuMobileSeparatorIcon,
} from '../../components/icons';
import { ExitButton } from '../../components/styled';
import { MobileMenu } from './';
import {
  MaterialSelect,
  MaterialMenuItem,
} from '../../components';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  min-width: 30%;
  min-height: 20%;
  background-color: ${theme.colors.grey1};
  border: 1px solid ${theme.colors.grey2};
  z-index: 3;
  border-radius: 5px;

  display: flex;
  flex: 1;
  flex-direction: column;
`;

const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StyledIcon = styled(LoginChainIcon)`
  height: 85px;
  width: 85px;
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 1em;
`;

const Title = styled.p`
  font-size: ${theme.fontSizeLg}px;
  color: ${theme.colors.primary};
  text-align: left;
  font-weight: bold;
`;

const SubTitle = styled.p`
  font-size: ${theme.fontSize}px;
  color: ${theme.colors.grey3};
  font-weight: bold;
  text-align: left;
  margin-left: ${props => (props.marginLeft ? '3px' : '0px')};
`;

const StyledText = styled.p`
  color: ${props =>
    props.color ? theme.colors.primary : theme.colors.secondary};
  font-size: ${props =>
    props.title ? theme.fontSize * 0.9 : theme.fontSize}px;
  font-weight: bold;
  margin-bottom: 12px;
  margin-left: 3px;
`;

const TitleAndSub = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 1em;
`;

const SectionsContainer = styled.div`
  margin: 5% 30px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LineTextContainer = styled.div`
  display: flex;
  height: 25px;
  padding: 10px 15px 10px 0px;
  align-items: center;
`;

const SelectGroup = styled(FormControl)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 40px;
`;

const Separator = styled(MenuMobileSeparatorIcon)`
  margin: 40px;
`;

const MobileMenuModal = () => {
  const node = useRef();
  // Access global state and global dispatch function
  const [{ visibility, session }, dispatch] = useStateValue();

  const {
    userName, stage, count, balance, category, selectedEvent, selectedRegion, userType,
  } = session;

  const initialFormState = {
    selectedEvent,
    selectedRegion,
  };

  const handleClick = event => {
    if (!node.current) {
      return;
    }
    if (node.current.contains(event.target)) {
      return;
    }
    if (event.originalTarget.id === 'SELECT-OPTION') {
      return;
    }
    dispatch({
      type: 'toggleMobileMenu',
      newVisibility: false,
    });
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const [, { select }] = useFormState(
    initialFormState,
    {
      withIds: true,
      onChange(e) {
        const { name, value } = e.target;
        console.log(`the ${name} input has changed to ${value}`);
        dispatch({
          type: 'changeSessionForm',
          field: name,
          newText: value,
        });
      },
    }
  );

  const mockEvents = [
    {
      key: 0,
      value: '10/10/2019 - Casamento Fulano',
    },
    {
      key: 1,
      value: '12/10/2019 - Casamento Ciclano',
    },
    {
      key: 2,
      value: '17/11/2019 - Casamento Beltrano',
    },
  ];

  const mockRegions = [
    {
      key: 0,
      value: 'MG - Belo Horizonte',
    },
    {
      key: 1,
      value: 'SP - São Paulo',
    },
  ];

  const eventOptions = mockEvents.map(({ key, value, }) => (
    <MaterialMenuItem
      value={value}
      key={key}
      id="SELECT-OPTION"
    >
      {value}
    </MaterialMenuItem>
  ));

  const regionOptions = mockRegions.map(({ key, value, }) => (
    <MaterialMenuItem
      value={value}
      key={key}
      id="SELECT-OPTION"
    >
      {value}
    </MaterialMenuItem>
  ));

  return (
    <>
      {visibility.mobileMenu && (
        <Wrapper ref={node}>
          <ExitButton
            left={'88%'}
            top={'15px'}
            onClick={() =>
              dispatch({
                type: 'toggleMobileMenu',
                newVisibility: !visibility.mobileMenu,
              })}
          >
            x
          </ExitButton>
          <ModalHeader>
            <IconsWrapper>
              <StyledIcon />
            </IconsWrapper>
            <TitleAndSub>
              <Title>{userName} </Title>
              <LineTextContainer>
                <SubTitle>Categoria:</SubTitle>
                <SubTitle marginLeft>{category}</SubTitle>
              </LineTextContainer>
              <LineTextContainer>
                <SubTitle>Pontos: </SubTitle>
                <StyledText color title>
                  {balance}
                </StyledText>
              </LineTextContainer>
              <LineTextContainer>
                <SubTitle> {`${stage}: `} </SubTitle>
                <StyledText title>{`${count} dias para o evento`}</StyledText>
              </LineTextContainer>
            </TitleAndSub>
          </ModalHeader>
          <SectionsContainer>
            <SelectGroup>
              <StyledText color>Operando evento:</StyledText>
              <MaterialSelect
                {...select({
                  name: 'selectedEvent',
                  validate: () => {},
                })}
              >
               {eventOptions}
              </MaterialSelect>
            </SelectGroup>
            <SelectGroup>
              <StyledText color>Fornecedores de:</StyledText>
              <MaterialSelect
                {...select({
                  name: 'selectedRegion',
                  validate: () => {},
                })}
              >
               {regionOptions}
              </MaterialSelect>
            </SelectGroup>
          </SectionsContainer>
          <IconsWrapper>
            <Separator />
          </IconsWrapper>
          <MobileMenu
            isGuest={userType === 'guest' ? true : false}
          />
        </Wrapper>
      )}
    </>
  );
};

MobileMenuModal.propTypes = {};

export default MobileMenuModal;
