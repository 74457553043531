import React from 'react';
import styled from 'styled-components';

import theme from '../styles/theme';

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  margin: 1em;
`;

const Title = styled.p`
  font-size: ${theme.fontSizeXlg}px;
  color: ${theme.colors.primary};
  text-align: left;
  font-weight: bold;
  margin: 0;
`;

const Description = styled.p`
  font-size: ${theme.fontSizeMed}px;
  color: ${theme.colors.grey3};
  text-align: left;
  font-weight: 300;
  margin: 0;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 1em;
`;

const IconWrapper = styled.div`
  height: 120px;
  width: 120px;
`;

export const Step = ({ icon, title, description }) => {
  return (
    <Wrapper>
      <IconWrapper>
        {icon}
      </IconWrapper>
      <TextWrapper>
        <Title>
          {title}
        </Title>
        <Description>
          {description}
        </Description>
      </TextWrapper>
    </Wrapper>
  );
};

export default Step;