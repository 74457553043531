import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import favicon from '../../images/favicon-md.png';
import { PageMeta } from '../';

export const FallbackPageMeta = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);
  return (
    <PageMeta
      title={site.siteMetadata.title}
      seoDescription={site.siteMetadata.description}
    >
      <link rel="icon" type="image/png" sizes="64x64" href={favicon} />
    </PageMeta>
  );
};

FallbackPageMeta.displayName = 'FallbackPageMeta';

export default FallbackPageMeta;
