import React from 'react';
import Select from '@material-ui/core/Select';
import classnames from 'classnames';

import { theme } from '../styles/theme';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  cssLabel: {
    fontSize: theme.fontSizeSm,
    '&$cssFocused': {
      fontSize: theme.fontSizeSm,
    },
  },
  cssInput: {
    fontSize: theme.fontSizeSm,
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: theme.colors.grey3,
    },
  },
};

const MaterialSelect = props => {
  const { classes } = props;

  return (
    <Select
      {...props}
      margin="dense"
      InputProps={{
        classes: {
          root: classes.cssInput,
          focused: classes.cssFocused,
          underline: classes.cssUnderline,
        },
      }}
      className={classnames(classes.cssLabel, classes.cssUnderline)}
      placeholder="Selecione"
    />
  );
};

// All material textfield props
MaterialSelect.propTypes = {
};

export default withStyles(styles)(MaterialSelect);
