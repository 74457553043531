// Use this file to add custom CSS to your global styles ;)

export const customStyles = `
// helpers for positioning text
.text_right {
  text-align: right;
  margin-right: 0;
  margin-left: auto;
}
.text_center {
  text-align: center;
  margin-right: auto;
  margin-left: auto;
}
`;

export default customStyles;
