import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes, { object } from 'prop-types';

import { medias } from '../utils/mediaQueries';
import { theme } from '../styles/theme';
import header2 from '../../src/assets/images/header2.jpg';

const StyledContainer = styled.div`
  @media ${medias.laptopMin} {
    /* max-width: 30%; */
    flex-direction: column;
    justify-content: space-around;
  }

  /* display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; */
`;

const StyledTitleContainer = styled.div`
  @media ${medias.laptopMin} {
    width: 100%;
  }

  /* width: 40%; */
`;

const LinkA = styled.a`
  text-decoration: none;
`;

const ImageContainer = styled.div`
  text-align: center;
  width: 100%;
`;

const Line = styled.div`
  @media ${medias.laptopMin} {
    width: 60px;
    height: 4px;
    margin: 0;
  }

  height: 2px;
  width: 60px;
  margin: 10px 0 10px 1px;
  background-color: ${theme.colors.primary};
`;

const Title = styled.h4`
  @media ${medias.laptopMin} {
    font-size: 18px;
    font-weight: bold;
    color: ${theme.colors.text_heading};
  }

  @media ${medias.phoneMax} {
    font-size: 18px;
  }

  margin: 1.33em 0;
  font-size: 18px;
  text-align: left;
  color: ${theme.colors.secondary};
`;

const StyledImage = styled.img`
  border-radius: 30px;
`;

class BlogPost extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    const { title, link } = this.props;
    return (
      <StyledContainer>
        {/* {posts.length > 1 && posts.map((item, key) => (
               <p key={key}>dentro</p>
          ))} */}
        <ImageContainer>
          <LinkA href={link} target="_blank noopener noreferrer">
            <StyledImage src={header2} />
          </LinkA>
        </ImageContainer>
        <StyledTitleContainer>
          <Title>
            <LinkA href={link} target="_blank noopener noreferrer">
              {title.toUpperCase()}
            </LinkA>
          </Title>
          <Line />
          {/* <Text dangerouslySetInnerHTML={{ __html:content}}>
              {content}
            </Text> */}
        </StyledTitleContainer>
      </StyledContainer>
    );
  }
}

BlogPost.propTypes = {
  posts: PropTypes.arrayOf(object),
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  image: PropTypes.string,
  date: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default BlogPost;
