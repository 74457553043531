import produce from 'immer';

export default (visibility, action) => {
  // ----> Logs
  // console.log('Called visibility reducer');
  // console.log(`Called action type ${action.type}`);
  // console.log('Prev state:');
  // console.table(visibility);
  // console.log('Action payload:');
  // console.table(action);
  
  switch (action.type) {
    case 'toggleLoginModal':
      return produce(visibility, draft => {
        draft.loginModal = action.newVisibility;
      });

    case 'togglePasswordModal':
      return produce(visibility, draft => {
        draft.passwordModal = action.newVisibility;
      });

    case 'toggleFianceeAccess':
      if (action.newVisibility) {
        return produce(visibility, draft => {
          draft.fianceeAccess = action.newVisibility;
          draft.supplierAccess = !action.newVisibility;
          draft.franchiseAccess = !action.newVisibility;
        });
      }
      return produce(visibility, draft => {
        draft.fianceeAccess = action.newVisibility;
        draft.passwordModal = false;
      });

    case 'toggleSupplierAccess':
      if (action.newVisibility) {
        return produce(visibility, draft => {
          draft.fianceeAccess = !action.newVisibility;
          draft.supplierAccess = action.newVisibility;
          draft.franchiseAccess = !action.newVisibility;
        });
      }
      return produce(visibility, draft => {
        draft.supplierAccess = action.newVisibility;
        draft.passwordModal = false;
      });

    case 'toggleFranchiseAccess':
      if (action.newVisibility) {
        return produce(visibility, draft => {
          draft.fianceeAccess = !action.newVisibility;
          draft.supplierAccess = !action.newVisibility;
          draft.franchiseAccess = action.newVisibility;
        });
      }
      return produce(visibility, draft => {
        draft.franchiseAccess = action.newVisibility;
        draft.passwordModal = false;
      });

    case 'toggleRestrictAccess':
      return produce(visibility, draft => {
        draft.restrictAccess = action.newVisibility;
      });
      
    case 'toggleSuppliersInfo':
      return produce(visibility, draft => {
        draft.suppliersInfo = action.newVisibility;
      });

    case 'toggleBudgetForm':
      return produce(visibility, draft => {
        draft.budgetForm = action.newVisibility;
      });

    case 'toggleDetailsAndAdvantages':
      return produce(visibility, draft => {
        draft.detailsAndAdvantages = action.newVisibility;
      });

    case 'toggleAdvantageDetails':
      return produce(visibility, draft => {
        if (action.newVisibility === visibility.advantageDetails) {
          draft.advantageDetails = -1;
        } else {
          draft.advantageDetails = action.newVisibility;
        }
      });

    case 'toggleMobileMenu':
      // return {
      //   ...visibility,
      //   mobileMenu: action.newVisibility,
      // };
      
      // 'visibility' = Estado antigo, nao alteravel
      // 'draft' = Estado antigo alteravel
      return produce(visibility, draft => {
        draft.mobileMenu = action.newVisibility;
      });

    default:
      return visibility;
  }
};
