import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import { useWindowWidth } from '../hooks';
import { CirclePlusIcon, CircleMinusIcon, Question } from './icons';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${props => (props.size ? props.size : '100%')};
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 0 0 1.5em;

  cursor: pointer;
`;

const Line = styled.div`
  background-color: ${theme.colors.grey1};
  height: 1px;
  width: 100%;
  margin-bottom: 0.8em;
`;

const SegmentItem = styled.div`
  display: flex;
  flex-direction: row;
  width: ${props => (props.phoneSize ? '42%' : '30%')};
  justify-content: center;
  align-items: center;
  margin: 0.5em 0.5em;
`;

const SegmentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0.8em 0 0.8em 0;
  padding-left: 0.8em;

  cursor: pointer;
`;

const ItemTitle = styled.h4`
  @media ${medias.phoneMax} {
    font-size: 0.8em;
  }

  font-size: ${theme.fontSize * 1.06}px;
  font-weight: bold;
  color: ${theme.colors.text_heading};
  margin: 0;
`;

const ItemText = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.65em;
  }

  display: flex;
  align-self: center;
  width: 83%;
  font-size: ${theme.fontSizeLg * 0.9}px;
  color: ${theme.colors.grey3};
  margin: ${props => (props.phoneSize ? '0 0 0 5px' : '0 0 0 10px')};
  overflow: hidden;
`;

const StyledIcon = styled(Question)`
  width: 30px;
  height: 35px;
  fill: ${props => props.color};
`;

const StyledIconDB = styled.img`
  width: 30px;
  height: 35px;
`;

const Width = () => {
  const screenWidth = useWindowWidth();
  return screenWidth;
};

//@TODO Slide-up and slide-down transitions
class SegmentList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openedStage: true,
      segmentList: [],
      selectedSegments: [],
    };

    this.handleClickSegment = id => {
      const { selectedSegments } = this.state;
      let newSelectedSegments;

      if (selectedSegments.includes(id)) {
        const i = selectedSegments.indexOf(id);
        selectedSegments.splice(i, 1);
        newSelectedSegments = [...selectedSegments];
      } else {
        newSelectedSegments = [...selectedSegments, id];
      }
      this.setState({ selectedSegments: newSelectedSegments });
    };
  }

  componentDidMount() {}

  render() {
    const { openedStage } = this.state;
    const { title, items } = this.props;
    return (
      <Wrapper>
        <Item>
          <Line />
          <TitleContainer
            onClick={() => this.setState({ openedStage: !openedStage })}
          >
            {openedStage ? <CircleMinusIcon /> : <CirclePlusIcon />}
            <ItemTitle>{title}</ItemTitle>
          </TitleContainer>
          {openedStage && (
            <SegmentContainer>
              {items.map(item => (
                <SegmentItem
                  key={item.id}
                  onClick={() => this.handleClickSegment(item.id)}
                  phoneSize={() => Width() < 760}
                >
                  {/* <Icones iconType={item.icon} /> */}
                  {item.icon ? (
                    <StyledIconDB src={item.icon} />
                  ) : (
                    <StyledIcon />
                  )}

                  <ItemText phoneSize={() => Width() < 760}>
                    <a
                      href={`/fornecedores/?seg=${item.codSegmento}`}
                      style={{ textDecoration: 'none' }}
                    >
                      {item.title}
                    </a>
                  </ItemText>
                </SegmentItem>
              ))}
            </SegmentContainer>
          )}
        </Item>
        <Line />
      </Wrapper>
    );
  }
}

SegmentList.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
    })
  ),
};

export default SegmentList;
