import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { medias } from '../utils/mediaQueries';
import theme from '../styles/theme';

const StyledTitle = styled.div`
  @media ${medias.laptopMin} {
    font-size: ${theme.fontSizeLg * 2.5}px;
  }

  @media ${medias.phoneMax} {
    font-size: 1.2em;
  }

  font-size: ${theme.fontSize * 2}px;
  margin-left: 0.5px;
  font-weight: ${theme.headingWeight * 1.4};
  text-align: left;
  color: ${theme.colors.secondary};
`;

const StyledSubtitle = styled.div`
  @media ${medias.laptopMin} {
    width: 80%;
    font-size: ${theme.fontSize * 1.2}px;
  }

  @media ${medias.phoneMax} {
    font-size: 1em;
  }

  font-size: ${theme.fontSize * 1.1}px;
  text-align: left;
`;

const StyledLine = styled.div`
  @media ${medias.laptopMin} {
    margin-top: 27px;
    margin-bottom: 39px;
    height: 6px;
    width: 118px;
  }

  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 1px;
  height: 2px;
  width: 60px;
  background-color: ${theme.colors.primary};
`;

const StyledDiv = styled.div`
@media (max-width: 1023px) {
  margin-top: 3rem!important;
}
`;

export const TitleSubtitle = props => {
  return (
    <StyledDiv>
      <StyledTitle>{props.title}</StyledTitle>
      <StyledLine />
      <StyledSubtitle>{props.subtitle}</StyledSubtitle>
    </StyledDiv>
  );
};

TitleSubtitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default TitleSubtitle;
