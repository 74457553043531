import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import logo from '../images/trust.png';

const Wrapper = styled.div`
  width: 100%;
  height: 275px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: ${theme.colors.secondary};
  padding-top: 1rem;
`;
const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;

const StyledSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 10px;
  padding-top: 1.5rem;
`;

const StyledBlogTitle = styled.h4`
  font-weight: 700;
  font-size: 20px;
  @media ${medias.phoneMax} {
    font-size: 0.85em;
  }

  color: ${theme.colors.primary};
  margin-bottom: 15px;
`;

const StyledLink = styled(Link)`
  @media ${medias.phoneMax} {
    font-size: 0.85em;
  }

  color: ${theme.colors.white};
  margin-bottom: 10px;
  text-decoration: none;

  cursor: pointer;

  &:hover {
    color: ${theme.colors.primary};
  }
`;

const logoStyle = {
  width: 'auto',
  height: '50px',
  margin: '0px 15px',
};

//@TODO Configure route to main page
const onClickLogo = () => {
  console.log('Logo clicked!');
};

const Footer = () => {
  return (
    <Wrapper>
      <StyledContainer>
        <StyledSubContainer>
          <img src={logo} style={logoStyle} onClick={onClickLogo} />
        </StyledSubContainer>
        <StyledSubContainer>
          <StyledBlogTitle>
            <a
              href="http://blog.certificadotrust.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: '#53adb3' }}
            >
              Blog
            </a>
          </StyledBlogTitle>
          <StyledLink to="/fornecedores">Fornecedores Certificados</StyledLink>
          <StyledLink to="/junte-se">Seja Certificado</StyledLink>
          <StyledLink to="/sobre">Quem Somos</StyledLink>
          <StyledLink to="/faq">Perguntas Frequentes</StyledLink>
          <StyledLink to="/contato">Ouvidoria/Fale Conosco</StyledLink>
        </StyledSubContainer>
      </StyledContainer>
    </Wrapper>
  );
};

export default Footer;
