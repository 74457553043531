import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import theme from '../styles/theme';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PhaseLabel = styled.span`
  font-size: ${theme.fontSize}px;
  color: ${theme.colors.text_heading};
  text-align: center;
  font-weight: bold;
  margin-bottom: 0.5em;
`;

const ClosedLabel = styled.span`
  font-size: ${theme.fontSizeXlg}px;
  text-align: center;
  color: ${theme.colors.primary};
  font-weight: bold;
`;

const RemainingLabel = styled.span`
  font-size: ${theme.fontSizeMed}px;
  text-align: center;
  color: ${theme.colors.grey3};
  font-weight: 300;
`;

export const ClosedSegmentsData = ({ title, closedSegments, totalSegments }) => {
  return (
    <Wrapper>
      <PhaseLabel>{title}</PhaseLabel>
      <ClosedLabel>{closedSegments}</ClosedLabel>
      <RemainingLabel>de {totalSegments}</RemainingLabel>
    </Wrapper>
  );
};

export default ClosedSegmentsData;

ClosedSegmentsData.propTypes = {
  title: PropTypes.string.isRequired,
  closedSegments: PropTypes.number.isRequired,
  totalSegments: PropTypes.number.isRequired,
};
