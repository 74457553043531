import React from 'react';
import styled from 'styled-components';
//import { useStaticQuery, graphql } from 'gatsby';
//import Img from 'gatsby-image';

import { theme } from '../styles/theme';
import { medias } from '../utils/mediaQueries';
//import { CirclePlusIcon } from './icons';
import './StoryCard.css';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  align-self: center;
  margin-top: 1.5em;
`;

const Line = styled.div`
  width: 70px;
  height: 3px;
  background-color: ${theme.colors.primary};
  align-self: center;
  margin: 10px 0 10px 0;
`;

const Card = styled.div`
  height: auto;
  display: flex;
  flex-direction: row;
  background-color: ${theme.colors.grey1};
  margin: 25px 0 0 0;
  padding: 20px;

  @media ${medias.phoneMax} {
    font-size: 1.2rem;
  }
`;

/*const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0 0 10px;
`;*/

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const PageTitle = styled.h3`
  font-style: normal;
  font-weight: ${theme.bodyWeight};
  align-self: center;
  margin: 0;
`;

/*const CardTitle = styled.h2`
  color: ${theme.colors.grey3};
  align-self: center;
`;*/

const CardText = styled.div`
  @media ${medias.phoneMax} {
    font-size: 0.8em;
  }

  font-size: 1.1rem;
  text-align: center;
`;

/*const IconText = styled.p`
  @media ${medias.phoneMax} {
    margin: 0.3em 0 0 0;
    font-size: 0.8em;
  }
  
  font-size: ${theme.colors.fontsize * 1.06}px;
  font-weight: bold;
  color: ${theme.colors.secondary};
  margin: 0;
`;*/

/*const StyledIcon = styled(CirclePlusIcon)`
  margin: 2px 0 0 0;
  cursor: pointer;
`;*/

/*const StyledImage = styled(Img)`
  @media ${medias.phoneMax} {
    max-width: 100%;
  }

  display: flex;
  flex: 1;
  align-self: center;
`;*/

/*const ImageContainer = styled.div`
  @media ${medias.phoneMax} {
    max-width: 55%;
  }
`*/

//@TODO Find the propér image and assets
const StoryCard = () => {
  return (
    <Wrapper>
      <PageTitle>QUEM SOMOS</PageTitle>
      <Line />

      <Card>
        <SubContainer>
          <CardText>
            <p>
              <b>
                A Empresa que facilita a análise da confiança e qualidade dos
                fornecedores para eventos.
              </b>
            </p>
            <p>
              A <b>Rede Qualidade Eventos</b> surgiu em 2015 com o objetivo
              inicial de ajudar os noivos na escolha de fornecedores confiáveis
              e idôneos. Em 2018, formatou o Certificado TRUST como consolidação
              de 3 anos de pesquisa no mercado.
            </p>
            <p>
              Assim, definiu-se quais indicadores seriam relevantes para a
              análise de empresas para deixar qualquer cliente, não apenas
              noivos, mais confiante em suas contratações.
            </p>
          </CardText>
        </SubContainer>
      </Card>
    </Wrapper>
  );
};

export default StoryCard;
