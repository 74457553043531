import produce from 'immer';

export default (session, action) => {
  // ----> Logs
  // console.log('Called session reducer');
  // console.log(`Called action type ${action.type}`);
  // console.log('Prev state:');
  // console.table(session);
  // console.log('Action payload:');
  // console.table(action);

  switch (action.type) {
    case 'registerSession':
      return produce(session, draft => {
        draft.userId = action.userId;
        draft.balance = action.balance;
        draft.userType = action.userType;
      });

    case 'changeSessionForm':
      return produce(session, draft => {
        draft[action.field] = action.newText;
      });

    default:
      return session;
  }
};
