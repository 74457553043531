import MomentUtils from '@date-io/moment';
import React from "react";
import { DatePicker } from "material-ui-pickers";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from 'material-ui-pickers';

import theme from '../styles/theme';

const styles = () => ({
  input: {
    fontSize: theme.fontSize,
    color: theme.colors.grey3,
    '&$cssFocused': {
      fontSize: theme.fontSize,
      color: theme.colors.grey3,
    },
  },
  cssFocused: {
    color: theme.colors.grey3,
  },
  cssUnderline: {
    '&:after': {
      borderBottomColor: theme.colors.grey3,
    }
  }
});

const MaterialDatePicker = ({ classes, ...rest }) => (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <DatePicker
      {...rest}
      leftArrowIcon={<KeyboardArrowLeft />}
      rightArrowIcon={<KeyboardArrowRight />}
      InputLabelProps={{
        classes: {
          root: classes.input,
          focused: classes.cssFocused,
        },
      }}
      InputProps={{
        classes: {
          root: classes.input,
          focused: classes.cssFocused,
          underline: classes.cssUnderline,
        },
      }}
      keyboard
      mask={value =>
        value
          ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
          : []
      }
      disableOpenOnEnter
      animateYearScrolling={false}
    />
  </MuiPickersUtilsProvider>
);

MaterialDatePicker.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MaterialDatePicker);