import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { theme } from '../styles/theme';
import { medias } from '../utils/mediaQueries';

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const Tab = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  cursor: pointer;
`;

const TabLine = styled.div`
  display: flex;
  align-self: flex-end;
  width: 100%;
  background-color: ${theme.colors.primary};
  height: 3px;
`;

const TabGreyLine = styled.div`
  background-color: ${theme.colors.grey2};
  height: 2px;
  margin: 1px 0 1px 0;
  width: ${props => props.width || '100%'};
`;

const TabTitle = styled.h3`
  @media ${medias.phoneMax} {
    font-size: 0.7em;
  }

  display: flex;
  align-self: center;
  color: ${props =>
    props.selected ? theme.colors.primary : theme.colors.grey3};
  font-weight: ${props => (props.selected ? 'bold' : 'normal')};
  font-size: ${theme.fontSizeLg * 0.9}px;
  margin: 0 0 15px 0;
`;

const Tabs = props => {
  const { content, onClick, className, components, lineWidth } = props;
  const [selectedTab, setSelectedTab] = useState(0);

  const handleClick = id => {
    setSelectedTab(id);
    onClick(id);
  };

  const page = components ? components[selectedTab] : null;

  return (
    <>
      <Container className={className}>
        {content.map(({ id, title }) => (
          <Tab onClick={() => handleClick(id)} key={id}>
            <TabTitle selected={selectedTab === id}>{title}</TabTitle>
            {selectedTab === id && <TabLine />}
          </Tab>
        ))}
      </Container>
      <TabGreyLine
        width={lineWidth}
      />
      {page}
    </>
  );
};

Tabs.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  components: PropTypes.arrayOf(PropTypes.node),
  lineWidth: PropTypes.string,
};

export default Tabs;
