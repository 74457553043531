import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  margin: 0 0 20px 0;
`;

const QuoteCard = styled.div`
  @media ${medias.phoneMax} {
    width: 150px;
  }

  position: relative;
  display: flex;
  align-self: center;
  flex-direction: column;
  width: 290px;
  height: 195px;
  border: 1px solid ${theme.colors.grey2};
  border-radius: 20px;
`;

const NameContainer = styled.div`
  position: absolute;
  top: 85%;
  width: 85%;
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: space-between;
`;

const Name = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.75em;
  }

  font-size: ${theme.fontSize * 1.06}px;
  color: ${theme.colors.text_heading};
  font-weight: bold;
  margin: 0;
`;

const CardText = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.75em;
  }

  font-size: ${theme.fontSize * 1.06}px;
  color: ${theme.colors.grey3};
  width: 85%;
  align-self: center;
`;

const TestimonialsCard = props => {
  const { name, text } = props;

  const data = useStaticQuery(graphql`
    query {
      quoteImage: file(relativePath: { eq: "quote.png" }) {
        childImageSharp {
          fixed(width: 26, height: 23) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <QuoteCard>
        <CardText>{text}</CardText>
        <NameContainer>
          <div>
            <Name>{name}</Name>
          </div>
          <div>
            <Img fixed={data.quoteImage.childImageSharp.fixed} />
          </div>
        </NameContainer>
      </QuoteCard>
    </Wrapper>
  );
};

TestimonialsCard.propTypes = {
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
};

export default TestimonialsCard;
