const initialState = {
  userId: '',
  userType: '',
  selectedRegion: '',
  selectedEvent: '',
  balance: 150,
  events: [],
  regions: [],
  // balance: null,
  userName: 'Fulano de tal',
  // userName: '',
  stage: 'Fase final',
  // stage: '',
  count: 20,
  // count: null,
  category: 'Light',
  // category: '',
};

export default initialState;
