import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { theme } from '../styles/theme';
import { Button } from '../components/';
import { ExitButton } from '../components/styled';

const Wrapper = styled.div`
  position: absolute;
  width: 50%;
  min-height: 45%;

  position: fixed;
  left: 25%;

  background-color: ${theme.colors.grey1};
  border: 1px solid ${theme.colors.grey2};
  z-index: 5;
  border-radius: 15px;

  display: flex;
  flex-direction: column;
`;

const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  width: 92%;
`;

const Title = styled.h4`
  font-size: ${theme.fontSize * 0.94}px;
  font-weight: bold;
  color: ${theme.colors.text_heading};
  margin: 30px 0 20px 0;
`;

const Text = styled.p`
  font-size: ${theme.fontSizeLg * 0.9}px;
  color: ${theme.colors.text_heading};
  margin: 0 0 20px 0;
`;

const StyledButton = styled(Button)`
  font-size: ${theme.fontSizeSm}px;
  font-weight: 500;
  color: ${theme.colors.error};
  border: 1px solid ${theme.colors.error};
  margin-bottom: 0.75em;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${theme.colors.error};
`;

const RestrictAcessModal = ({ onClickClose }) => {
  return (
    <>
      <Wrapper>
        <InnerContainer>
          <ExitButton onClick={onClickClose} left={'96%'} top={'2.5%'} />
          <Title>ACESSO RESTRITO</Title>
          <Text>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor. Lorem ipsum dolor sit amet, consectetur adipiscing
            elit, sed do eiusmod tempor.
          </Text>
          <StyledLink to="/junte-se">
            <StyledButton text="FAÇA PARTE" click={onClickClose} />
          </StyledLink>
        </InnerContainer>
      </Wrapper>
    </>
  );
};

export default RestrictAcessModal;
