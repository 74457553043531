import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { theme } from '../styles/theme';

const Wrapper = styled.div`
  border: 2px solid ${theme.colors.primary};
  border-radius: 3px;
  color: ${theme.colors.text_heading};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 35px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
`;

const Button = props => {
  const { click, text, className } = props;

  return (
    <Wrapper className={className} onClick={click}>
      {text}
    </Wrapper>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  click: PropTypes.func,
  className: PropTypes.string,
};

export default Button;
