import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
//import Img from 'gatsby-image';

import { Question } from '../components/icons';
import theme from '../styles/theme';
import { hexToRgb } from '../utils/hexToRgb';
import { medias } from '../utils/mediaQueries';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: right;
  width: 100%;
  height: auto !important;

  align-self: right;
  padding: 1em;
  margin: 1em;

  background-color: ${theme.colors.white};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 3px 10px 0px ${hexToRgb(theme.colors.grey2, 0.5)};

  @media ${medias.phoneMax} {
    width: 95%;
    padding: 0.5em;
    margin: 0.5em;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    box-shadow: 0px 3px 3px 0px ${hexToRgb(theme.colors.grey2, 0.5)};
  }
`;

const TopContainer = styled.div`
  justify-content: center;
`;

const TextContainer = styled.div`
  @media ${medias.phoneMax} {
    width: 90%;
  }
`;

const CardTitle = styled.p`
  color: ${theme.colors.text_heading};
  font-size: ${theme.fontSize};
  font-weight: bold;
  text-align: left;
  margin-top: 0.8rem;
  margin-bottom: 0;
`;

const CardText = styled.div`
  color: ${theme.colors.text_heading};
  font-size: ${theme.fontSize}px;
  font-weight: 300;
  text-align: left;
  margin: 0;
`;

const StyledIcon = styled(Question)`
  width: 30px;
  height: 35px;
  fill: ${props => props.color};
`;

const StyledIconDB = styled.img`
  width: 30px;
  height: 35px;
`;

const SupplierCard = ({
  empresa,
  nomeFantasia,
  cnpj,
  outrosDados,
  segmento,
  validade,
  cidade,
  finalizadoEm,
  icone,
}) => {
  return (
    <>
      <Wrapper>
        <TopContainer>
          <TextContainer>
            <CardTitle>{nomeFantasia}</CardTitle>
            <CardTitle>
              {icone ? <StyledIconDB src={icone} /> : <StyledIcon />} {segmento}{' '}
              - {cidade}
            </CardTitle>
            <CardText>{empresa}</CardText>
            <CardText>{cnpj}</CardText>
            <CardText>{outrosDados}</CardText>
            <CardTitle>Dados da certificação</CardTitle>
            <CardText>{finalizadoEm}</CardText>
            <CardText>{validade}</CardText>
          </TextContainer>
        </TopContainer>
      </Wrapper>
    </>
  );
};

SupplierCard.propTypes = {
  icons: PropTypes.arrayOf(PropTypes.node),
  img: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  isFavorite: PropTypes.bool,
};

export default SupplierCard;
