import React from 'react';
import styled from 'styled-components';

import theme from '../styles/theme';
import { hexToRgb } from '../utils/hexToRgb';
import { ClosedSegmentsData } from './';

const Card = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 90%;
  padding: 1em 1em 2em 1em;

  border: 1px solid ${theme.colors.grey1};
  border-radius: 20px;
  background-color: ${theme.colors.white};
  box-shadow: 0px 4px 8px 0 ${hexToRgb(theme.colors.grey2, 0.5)};
`;

const Title = styled.h2`
  font-size: ${theme.fontSizeXlg}px;
  color: ${theme.colors.grey3};
  text-align: center;
  font-weight: bold;
  margin: 2.5em 0 0.75em 0;
`;

export const ClosedSegments = () => {
  return (
    <>
      <Title>Segmentos fechados</Title>
      <Card>
        <ClosedSegmentsData
          title="Fase Inicial"
          closedSegments={5}
          totalSegments={11}
        />
        <ClosedSegmentsData
          title="Fase Intermediária"
          closedSegments={6}
          totalSegments={16}
        />
        <ClosedSegmentsData
          title="Fase Final"
          closedSegments={15}
          totalSegments={29}
        />
      </Card>
    </>
  );
};

export default ClosedSegments;
